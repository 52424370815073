.active {
  background: #7689eb !important;
  color: #fff !important;
}

.wrap {
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  color: #000;
  padding: 10px 15px;
}

.wrap svg {
  margin-right: 10px;
}
