@import '~animate.css';
@import '~react-vis/dist/style.css';
@import-normalize;

@import './transitions.css';

* {
  outline: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  background: #f8f9ff;
  font-family: Avenir, sans-serif;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 19px;
  margin: 0;
}

button {
  border: none;
  cursor: pointer;
  font: inherit;
  font-size: 11px;
  line-height: 15px;
}

h1 {
  color: #272727;
  font-size: 24px;
  line-height: 33px;
}

h2 {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  line-height: 21px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input {
  appearance: none !important;
  background: #fff;
  border: 1px solid #e9eff4;
  border-radius: 6px;
  color: #323c47;
  font: inherit;
  font-size: 16px;
  line-height: 26px;
  padding: 6px 12px;
}

input::placeholder {
  color: rgba(152, 152, 152, 0.5); /* TODO: ask Tycho for hex. */
  font-weight: bold;
}

ul {
  margin: 0;
}

.button {
  background: #7689eb;
  border-radius: 2px;
  color: #fff;
  padding: 6px 12px;
}

.button:disabled {
  background: rgba(0, 0, 0, 0.04); /* TODO: ask Tycho for hex. */
  color: #000;
}

.button.transparent {
  background: transparent;
  color: #7689eb;
}

.circle {
  align-items: center;
  background: #f2f2f2;
  border-radius: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.circle.small {
  background: #f8f9ff;
  height: 36px;
  width: 36px;
}
