.input {
  background: transparent;
  border: none;
  border-radius: 0; /* TODO: ook dit is mooier met Tailwind, in plaats van alles te resetten. */
  color: rgba(0, 0, 0, 0.4);
  flex: 1;
  font-size: 14px;
  line-height: 19px;
}

.input::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-weight: normal;
}

.wrap {
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  padding: 7px 17px;
}
