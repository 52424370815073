.actions {
  align-self: center;
  margin-left: 20px;
  width: 124px;
}

.actions > *:not(:first-child) {
  margin-left: 10px;
}

.play {
  align-items: center;
  align-self: center;
  display: flex;
}

.play > * {
  flex-shrink: 0;
}

.play > *:not(:first-child) {
  margin-left: 20px;
}

.text {
  color: #323c47;
  flex-basis: 44%;
  flex-grow: 1;
  font-weight: bold;
  line-height: 21px;
  margin: 9px 18px;
}

.waveform {
  height: 45px;
  width: 200px;
}

.wrap {
  background: #fff;
  border: 1px solid #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);
  display: flex;
  min-height: 100px;
  overflow: hidden; /* TODO: remove this after making things responsive. */
  padding: 15px;
}

.wrap > * {
  flex-shrink: 0;
}
