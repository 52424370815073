.form {
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
}

.form button {
  align-self: center; /* TODO: Tailwind would handles this better. */
}

.form input {
  margin-bottom: 10px;
}

.header {
  background: #7689eb;
  color: #fff;
  padding: 30px 90px 25px 90px;
}

.logo {
  height: 30px;
}

.wrap {
  border: 1px solid #f9f9f9;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);
}
