.header {
  align-items: center;
  display: flex;
}

.search {
  flex: 1;
  margin-left: 20px;
}

.tabs {
  background: #f8f9ff;
  margin-top: 20px;
  padding: 20px 0;
  position: sticky;
  top: 81px;
  z-index: 10;
}

.wrap {
  padding: 50px 120px;
  width: 100%;
}
