.row {
  display: flex;
}

.row:not(:first-of-type) {
  margin-top: 15px;
}

.row > * {
  flex: 1;
  overflow: hidden;
}

.row > :not(:first-child) {
  margin-left: 30px;
}

.tabs {
  background: #f8f9ff;
  margin-top: 20px;
  padding: 20px 0;
  position: sticky;
  top: 81px;
  z-index: 10;
}

.title {
  margin-bottom: 15px;
}

.wrap {
  padding: 50px 120px;
  width: 100%;
}
