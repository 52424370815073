.transition-list-appear,
.transition-list-enter {
  opacity: 0;
  transform: scale(0.9);
}

.transition-list-appear-active,
.transition-list-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
