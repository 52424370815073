.active {
  border-bottom: 2px solid #7689eb;
  color: #7689eb !important;
}

.logo {
  color: #7689eb;
  height: 15px;
  margin: 0 15px;
}

.nav {
  display: flex;
  flex: 1;
  list-style: none;
  margin: 0 15px;
}

.nav-item {
  color: #000;
  padding: 30px 15px;
}

.profile {
  margin: 0 15px;
}

.wrap {
  align-items: center;
  background: #fff;
  display: flex;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}
